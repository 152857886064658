import React from 'react';
import './infiniteSlider.css'
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import content from "../../content.json";

function shuffle(array) {
    return array.sort(() => Math.random() - 0.5);
}

const InfiniteSlider = () => {

    const ticker = [...content.services.head.cards, ...content.services.makeup.cards, ...content.services.hand.cards, ...content.services.feet.cards ]
    const redirect = (link)=>{
        window.location = 'https://n1142833.yclients.com/company/1052557/personal/select-services' + link
    }

    return (
        <div className={'infiniteSlider'}>

            <Splide
                options={{
                    width: '100%',
                    arrows: false,
                    pagination: false,
                    autoWidth: true,
                    gap: '20px',
                    type: 'loop',
                    focus: 'center',
                        autoScroll: {
                            pauseOnHover: false,
                            pauseOnFocus: false,
                            rewind: false,
                            speed: 0.5,
                        }
                }}
                extensions={{AutoScroll}}
            >
                {
                    shuffle(ticker).map((val, index) => {
                        return <SplideSlide onClick={()=>{redirect(val.link)}} key={'InfiniteSlider_slide_' + index}>
                            <div className={'slide'}>{val.title}</div>
                        </SplideSlide>
                    })
                }
            </Splide>
            <Splide
                options={{
                    width: '100%',
                    arrows: false,
                    pagination: false,
                    autoWidth: true,
                    gap: '20px',
                    type: 'loop',
                    focus: 'center',
                    autoScroll: {
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        rewind: false,
                        speed: -0.5,
                    }
                }}
                extensions={{AutoScroll}}
            >
                {
                    shuffle(ticker).map((val, index) => {
                        return <SplideSlide onClick={()=>{redirect(val.link)}} key={'InfiniteSlider_slide_' + index}>
                            <div className={'slide'}>{val.title}</div>
                        </SplideSlide>
                    })
                }
            </Splide>
            <Splide
                options={{
                    width: '100%',
                    arrows: false,
                    pagination: false,
                    autoWidth: true,
                    gap: '20px',
                    type: 'loop',
                    focus: 'center',
                    autoScroll: {
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        rewind: false,
                        speed: 1,
                    }
                }}
                extensions={{AutoScroll}}
            >
                {
                    shuffle(ticker).map((val, index) => {
                        return <SplideSlide onClick={()=>{redirect(val.link)}} key={'InfiniteSlider_slide_' + index}>
                            <div className={'slide'}>{val.title}</div>
                        </SplideSlide>
                    })
                }
            </Splide>

        </div>
    );
};

export default InfiniteSlider;



