import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import content from "../../content.json"; 
import './SalonSlider.css';

const SalonSlider = () => {
    const salonPhotos = content.salon.cards.map(card => card.img_url);

    return (
        <div className={'SalonSlider'}>
            <Splide
                className={'SalonSlider__splide'}
                hasTrack={false}
                options={{
                    width: '100%',
                    pagination: false,
                    autoWidth: true,
                    gap: '0', 
                    focus: 'start',
                    rewind: false,
                    omitEnd: true,
                    arrows: true,
                    autoScroll: {
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        rewind: false,
                        speed: 0.5,
                    }
                }}
            >
                <div className='SalonSlider__title'>
                    <p className={'SalonSlider__title__text'}>{content.salon.title}</p>
                    <div className={'SalonSlider__title__arrow splide__arrows'}>
                        <button className="splide__arrow splide__arrow--prev"></button>
                        <button className="splide__arrow splide__arrow--next"></button>
                    </div>
                </div>

                <SplideTrack>
                    {salonPhotos.map((url, index) => (
                        <SplideSlide key={index}>
                            <div className={'SalonSlider__card'}>
                                <img className={'SalonSlider__card__img'} src={url} alt={`Salon ${index}`} />
                            </div>
                        </SplideSlide>
                    ))}
                </SplideTrack>
            </Splide>
        </div>
    );
};

export default SalonSlider;