import React from 'react';
import "./css/NotFound.css"

const NotFound = () => {
    return (
        <div className={'OpenRegister'}>
            <span className={'OpenRegister__background'}></span>
            <div className="OpenRegister__content">
                <p className={'notFoundText_title'}>
                    404
                </p>
                <p className={'notFoundText'}>
                    Прости, такой страницы нет...
                </p>
                <div className={'goBackLink__box'}>
                    <a className={'goBackLink'} href="/">Хочу назад!</a>
                </div>
            </div>
        </div>
    );
};

export default NotFound;