import React, {useEffect, useState} from 'react';
import './Contacts.css'
import  content from '../../content.json'
import ModalForm from "../ModalForm/ModalForm";
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import mapIcon from './img/map_icon.png'




const Contacts = () => {
    const defaultState = {
        center: [55.793627, 49.116394],
        zoom: 16.5
    };
    let mapSize = {}
    const screenWidth =  window.innerWidth

    if (screenWidth >= 1400){
        mapSize = {x: 600, y: 600}
    }

    if (screenWidth <= 1400){
        mapSize = {x: 660, y: 600}
    }

    if (screenWidth <= 810){
        mapSize = {x: 380, y: 380}
    }

    if (screenWidth <= 400){
        mapSize = {x: 310, y: 310}
    }
   

    const handlePlacemarkClick = () => {
        window.open('https://yandex.ru/maps/?text=Кремлевская%2021', '_blank');
    };

    function yClients() {
        window.location = 'https://n1142833.yclients.com'
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className={'Contacts'}>
            <ModalForm show={show} handleClose={handleClose}/>
            <div>
                <p className={'Contacts__title'}>Контакты</p>
                <div className={'Contacts__contactsBox'}>

                    <a href={"tel:" + content.phone}>
                        <div className="Contacts__phone Contacts__contact">
                            <span></span>
                            <div>
                                <p className={'Contacts__contact__title'}>Номер телефона</p>
                                <p className={'Contacts__contact__description'}>{content.phone}</p>
                            </div>
                        </div>
                    </a>

                    <a href={"geo: 55.793596959705695, 49.116456419142494"}>
                        <div className="Contacts__address Contacts__contact">
                            <span></span>
                            <div>
                                <p className={'Contacts__contact__title'}>Адрес</p>
                                <p className={'Contacts__contact__description'}>{content.address}</p>
                            </div>
                        </div>
                    </a>
                    <a href={"mailto:" + content.email}>
                        <div className="Contacts__email Contacts__contact">
                            <span></span>
                            <div>
                                <p className={'Contacts__contact__title'}>E-mail</p>
                                <p className={'Contacts__contact__description'}>{content.email}</p>
                            </div>
                        </div>
                    </a>


                    <div className="Contacts__time Contacts__contact">
                        <span></span>
                        <div>
                            <p className={'Contacts__contact__title'}>Время работы</p>
                            <p className={'Contacts__contact__description'}>{content.time_day + " " + content.time_in + " - " + content.time_out}</p>
                        </div>
                    </div>


                </div>
                <div className={'Contacts__social'}>
                    <p className={'Contacts__social__title'}>Социальные сети</p>
                    <div className={'Contacts__social__linkBox'}>
                        <a href={content.links.inst}>
                            <span className={'Contacts__social__inst'}></span>
                        </a>

                        <a href={content.links.yt}>
                            <span className={'Contacts__social__vk'}></span>
                        </a>

                        <a href={content.links.tg}>
                            <span className={'Contacts__social__tg'}></span>
                        </a>

                    </div>
                    <p className={'Contacts__social__description'}>
                        <span className={'text_red'}>*</span> Meta - признана экстремистской <br/>
                        организацией в РФ.
                    </p>
                </div>
                <button onClick={()=>{yClients()}}>Записаться</button>
            </div>
            <div className={'mapContainer'}>
                {/*<iframe*/}
                {/*    className={'Contacts__map'}*/}
                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2242.9801036606727!2d49.113879077473335!3d55.79358338884862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415ead1658af9a1b%3A0x3d1f6b27fb6d955d!2z0YPQuy4g0JrRgNC10LzQu9C10LLRgdC60LDRjywgMjEsINCa0LDQt9Cw0L3RjCwg0KDQtdGB0L8uINCi0LDRgtCw0YDRgdGC0LDQvSwgNDIwMTEx!5e0!3m2!1sru!2sru!4v1716936839088!5m2!1sru!2sru"*/}
                {/*    allowFullScreen="" loading="lazy"*/}
                {/*    referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <YMaps>
                    <Map
                        defaultState={defaultState}
                        width={mapSize.x}
                        height={mapSize.y}
                        options={{
                            suppressMapOpenBlock: true,
                            yandexMapDisablePoiInteractivity: true,
                        }}
                    >
                        <Placemark geometry={[55.793511, 49.116050]} onClick={handlePlacemarkClick}/>
                        <Placemark geometry={[55.793714, 49.116427]} properties={{
                            iconContent: 'Кремлевская улица, 21',
                        }}  options={{
                            preset: 'islands#blackStretchyIcon', 
                        }} onClick={handlePlacemarkClick}/>
                    </Map>
                </YMaps>

            </div>

        </div>
    );
};

export default Contacts;