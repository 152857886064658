import React, {Component} from 'react';
import './AppointmentTextForm.css'
import ModalShowBtn from "../ModalShowBtn/ModalShowBtn";

class AppointmentTextForm extends Component {
    render() {
        return (
            <div className={'AppointmentTextForm'}>
                <p>Дорогая,</p>
                <p>Приходи к нам, расслабься и доверься нашим профессионалам.
                    Мы знаем, как важно для тебя выглядеть великолепно,
                    и готовы сделать все, чтобы ты сияла каждый день.</p>
                <ModalShowBtn/>
            </div>
        );
    }
}

export default AppointmentTextForm;