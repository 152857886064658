import React, {useState} from 'react';
import './ModalShowBtn.css'
import ModalForm from "../ModalForm/ModalForm";

const ModalShowBtn = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function yClients() {
        window.location = 'https://n1142833.yclients.com'
    }

    return (

        <>
            <ModalForm show={show} handleClose={handleClose}/>

            <button onClick={()=> yClients()} className={'ModalShowBtn'}>Записаться</button>


        </>

    );
};

export default ModalShowBtn;