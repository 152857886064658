import {useEffect} from 'react';

const Redirect = () => {

    useEffect(()=>{
        window.location = 'https://n1142833.yclients.com/company/1052557/personal/select-services?o='
    })

    return null
};

export default Redirect;