import React, {useEffect, useState} from 'react';
import './FeedbackForm.css'
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)
const FeedbackForm = () => {
    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')

    const sendHandler = ()=>{
        if(!sended){
            if ( document.getElementById('FeedbackForm__nameInput').value === '' || phone === ''){
                setEmpty(true)
                setPhone('')
                document.getElementById('FeedbackForm__nameInput').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://macik.beauty/api/mailer.php?name=' + document.getElementById('FeedbackForm__nameInput').value + '&phone=' + phone + '&action=job' ,
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }

    useEffect(() => {
        if(sended)setTextBtn('Заявка отправлена!')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Отправить заявку');
    }, [empty,error,sended]);

    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);

    return (
        <div className={'FeedbackForm'}>
            <span className={'FeedbackForm__background'}></span>
            <div className={'FeedbackForm__contentBox'}>
                <div className={'FeedbackForm__title'}>
                    <span className={'FeedbackForm__heart'}></span>
                    <p>Вступай в нашу <br/> команду</p>
                </div>
                <div className={'FeedbackForm__form'}>
                    <input id={'FeedbackForm__nameInput'} maxLength={80} placeholder={'Имя'} type="text"/>
                    <ReactInput
                        value={phone}
                        onChange={setPhone}
                        parse={parse}
                        format={format}
                        placeholder={'Телефон'}
                        inputMode={'tel'}
                    />
                    <button onClick={sendHandler}>{textBtn}</button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;