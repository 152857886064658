import React, { useState } from "react";
import "../ProductBlock/ProductBlock.css";
import ProductModal from "../ProductModal/ProductModal";

const ProductBlock = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = () => {
    setIsModalOpen(true);
  };
  //#C21602
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className="product"
        onClick={handleProductClick}
        style={{
          backgroundColor: product.default ? "" : "#C21602",
          color: product.default ? "" : "#F1EDE1",
        }}
      >
        <h2 className="product__title">{product.title}</h2>
        <div className="product__details">
          <button
            className="product__details-button"
            style={{
              backgroundColor: product.default ? "" : "#C21602",
              border: product.default ? "" : "2px solid #F1EDE1",
              color: product.default ? "" : "#F1EDE1",
            }}
          >
            Подробнее
          </button>
          <button className="product__weight-button" 
          style={{
            backgroundColor: product.default ? "" : "#F1EDE1",
            border: product.default ? "" : "2px solid #F1EDE1",
            color: product.default ? "" : "#C21602",
          }}>{product.weight}</button>
        </div>
        <img alt="Эспрессо" className="product__img" src={product.img_url} />
      </div>
      {isModalOpen && (
        <ProductModal product={product} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ProductBlock;
